import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import logo from "../img/dark-logo.png";
import lightLogo from "../img/light-logo.png";
import Alert from "./Alert";

const listWords = {
  MainPage: "首页",
  CoreTech: "产品与技术",
  News: "新闻中心",
  Career: "加入我们",
  About: "关于我们",
  Contact: "联系我们",
  "MainPage-en": "English",
};
const unShowUp = ["news"];

function Header({ history }) {
  const location = useLocation();
  const [y, setY] = useState(window.screenY);
  const [theme, setTheme] = useState("up");
  const [current, setCurrent] = useState(
    location.pathname.slice(1).split("/")[0]
  );
  const [show, setShow] = useState(false);
  const [showManu, setShowManu] = useState(false);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      setY(window.scrollY);
      if (unShowUp.includes(current.toLowerCase())) {
        setTheme("down");
      } else if (window.scrollY < 60 && window.innerWidth > 750) {
        setTheme("up");
      } else if (window.scrollY < 30 && window.innerWidth <= 750) {
        setTheme("up");
      } else {
        setTheme("down");
      }
    },
    [y]
  );

  useEffect(() => {
    setCurrent(location.pathname.slice(1).split("/")[0]);
    console.log(location, current.toLowerCase());
    setTimeout(() => {
      if (unShowUp.includes(current.toLowerCase())) {
        setTheme("down");
      } else {
        setTheme("up");
      }
    }, 100);
  }, [location, current]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [y]);

  const getCurrentColor = (item) => {
    if (current === "" && item === "MainPage" && theme === "down") {
      return "text-blue-400";
    }
    if (theme === "up") {
      return "text-white";
    }

    if (item === current) {
      return "text-blue-400";
    }
    return "text-black";
  };

  const getCurrentBorder = (item) => {
    if (current === "" && item === "MainPage") {
      if (theme === "down") {
        return "border-b-2 border-blue-600";
      }
      return "border-b-2 border-white";
    }
    if (current !== item) {
      return "border-b-0";
    }
    if (theme === "down") {
      return "border-b-2 border-blue-600";
    }
    return "border-b-2 border-white";
  };

  return (
    <div
      className={`${
        theme === "up" ? "bg-transparent top-0 fixed" : "bg-white top-0 fixed"
      } min-h-[5vw]  
    w-[100vw] flex flex-col px-[10vw] md:px-[15vw] justify-between
      text-center z-30 pt-[2vw] md:pt-0 pb-[1vw] md:pb-0
      ${current === "News" ? "shadow down" : ""}`}
    >
      {/* 图片 */}
      <div className="flex flex-row justify-between">
        <img
          src={`${theme === "up" ? lightLogo : logo}`}
          alt="not found"
          className="mx-auto w-[7.5vw] py-[1vw] ml-[0] z-90 sm:min-w-[85px] min-w-[80px] md:min-w-[60px]"
        />

        {/* 标题: > md */}
        <div className="flex-row flex-none md:flex z-90 hidden">
          <div className="w-[20vw]" />
          {Object.keys(listWords).map((item) => {
            if (item === "Career") {
              return (
                <a
                  href="https://app.mokahr.com/campus-recruitment/sinpro/98467"
                  rel="noopener noreferrer"
                  key={item}
                  className={`ml-[3vw] text-[1.2vw] flex items-center 
                  ${getCurrentColor(item)}  ${getCurrentBorder(item)}`}
                >
                  加入我们
                </a>
              );
            } else {
              return (
                <Link
                  to={`/${item}`}
                  key={item}
                  onClick={() => {
                    setCurrent(item);
                  }}
                  className={`ml-[3vw] text-[1.2vw] flex items-center 
                    ${getCurrentColor(item)}  ${getCurrentBorder(item)}`}
                >
                  <p>{listWords[item]}</p>
                </Link>
              );
            }
          })}
        </div>

        {/* mobile */}
        <button
          className="md:hidden z-90 flex justify-center items-center h-[8vw]"
          type="button"
          onClick={() => {
            setShowManu((state) => !state);
            setTheme("down");
          }}
        >
          {!showManu ? (
            <MenuOutlined
              style={{ fontSize: "6.5vw" }}
              className={`${theme === "up" ? "text-white" : "text-black"}`}
            />
          ) : (
            <CloseOutlined
              style={{ fontSize: "6.5vw" }}
              className={`${theme === "up" ? "text-white" : "text-black"}`}
            />
          )}
        </button>
      </div>

      {showManu && (
        <div className=" md:hidden z-90 flex flex-col">
          {Object.keys(listWords).map((item) => {
            if (item === "Career") {
              return (
                <a
                  href="https://app.mokahr.com/campus-recruitment/sinpro/98467"
                  rel="noopener noreferrer"
                  key={item}
                  className={`pl-[3vw] text-[3vw] flex items-center h-[10vw] border-gray-300 border-t-2 ${getCurrentColor(
                    item
                  )}`}
                >
                  加入我们
                </a>
              );
            } else {
              return (
                <Link
                  to={`/${item}`}
                  key={item}
                  onClick={() => {
                    setCurrent(item);
                    setShowManu(false);
                    setTheme("up");
                  }}
                  className={`pl-[3vw] text-[3vw] flex items-center h-[10vw] border-gray-300 border-t-2
                ${getCurrentColor(item)}`}
                >
                  {listWords[item]}
                </Link>
              );
            }
          })}
        </div>
      )}

      <Alert
        show={show}
        handleShow={() => {
          setShow(false);
        }}
        type="building"
      />
    </div>
  );
}

export default Header;
