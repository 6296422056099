import 'animate.css/animate.min.css';
import {AnimationOnScroll} from 'react-animation-on-scroll';
// import { useNavigate } from 'react-router-dom';
import {useEffect} from 'react';
import sparkle from './img/sparkle-static-2.png';
import sparkle2 from './img/sparkle-static-4.png';
import video from '../../img/video/video1-new.mov';
import video2 from '../../img/video/video2-new.mov';
import videoMobile from '../../img/video/video1-mobile.mov';
import videoMobile2 from '../../img/video/video2-mobile.mov';
import verticalBlock from './img/verticalBlock.png';
import bar from './img/bar.png';
import title from './img/titl.png';
import title2 from './img/ttl2.png';
import pic1 from './img/pic1.png';
import pic2 from './img/pic2.png';
import pic3 from './img/pic3.png';
import pic4 from './img/pic4.png';
import pic5 from './img/pic5.png';
import pic6 from './img/pic6.png';
import pic7 from './img/pic7.png';
import pic8 from './img/pic8.png';

import Foot from '../News/Foot';

export default function MainPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="bg-mainpage-background-mobile md:bg-mainpage-background bg-cover text-white w-[100vw] mt-[-4vw]">

        <img src={sparkle} alt="" className="md:top-[-0.3vw] md:left-[-0.3vw] md:w-[98.8vw] md:h-[64.6vw] top-[102.5vw] left-[40.5vw] w-[55vw] absolute animate-pulse-1 z-10" />
        <img src={sparkle2} alt="" className="md:top-[-0.3vw] md:left-[-0.25vw] md:w-[92vw] md:h-[58.5vw] top-[120vw] left-[10vw] w-[85vw] absolute animate-pulse-2 z-10" />

        <div className="md:w-[70vw] w-[80vw] mx-auto flex md:pt-[27vw] pt-[50vw] flex-col items-end">
          <AnimationOnScroll animateIn="animate__fadeInUpBig">
            <img src={bar} alt="" className="md:w-[10vw] md:h-[0.5vw] w-[20vw] h-[1vw]" />
          </AnimationOnScroll>
        </div>
        <AnimationOnScroll animateIn="animate__fadeInUp" delay={300}>
          <div className="items-end">
            <img src={title} alt="" className="md:mt-[3vw] mt-[6vw] md:ml-[36vw] ml-[12vw] md:w-[50vw] w-[80vw] md:h-[10vw] h-[16vw] text-right" />
          </div>

        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" delay={500}>
          <div className="items-end">
            <img src={title2} alt="" className="md:mt-[3vw] mt-[4vw] md:ml-[54vw] ml-[30vw] md:w-[30vw] w-[60vw] md:h-[3vw] h-[6vw] text-right" />
          </div>
        </AnimationOnScroll>

        <div className="flex flex-row md:w-[70vw] w-[80vw] mx-auto md:pt-[20vw] pt-[120vw] justify-between">

          <AnimationOnScroll animateIn="animate__fadeInDownBig" delay={200}>
            <img src={verticalBlock} alt="" className="w-[1vw] h-[16vw] md:w-[0.5vw] md:h-[13vw] md:mt-[2vw] mt-[22vw] ml-[0vw]" />
          </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__fadeInUpBig" delay={100} />
          <div className="flex flex-col items-end justify-between">

            <AnimationOnScroll animateIn="animate__fadeInUpBig" delay={200}>
              <div className="md:text-[3vw] text-[5vw] pt-[2vw] md:pb-[0vw] pb-[6vw]">产品方案</div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUpBig" delay={300}>
              <div className="md:flex flex-col items-end hidden ">
                <div className="text-[1.3vw] tracking-wide leading-[2.5vw] pt-[3.5vw] font-extralight">赛恩领动成像雷达支持多种布置方案</div>
                <div className="text-[1.3vw] tracking-wide font-extralight">实现360°全方位精确感知，满足高级别自动驾驶需求</div>
              </div>
              <div className="md:hidden flex-col items-end flex text-[3vw] tracking-wide font-extralight">
                <div className="mt-[1.5vw]">赛恩领动成像雷达</div>
                <div className="mt-[1.5vw]">支持多种布置方案</div>
                <div className="mt-[1.5vw]">实现360°全方位精确感知</div>
                <div className="mt-[1.5vw]">满足高级别自动驾驶需求</div>
              </div>

            </AnimationOnScroll>

          </div>
        </div>

        <div className="flex md:py-[10vw] py-[5vw] md:w-[70vw] w-[80vw] mx-auto">
          {/* pc */}
          <video autoPlay="autoplay" loop="loop" muted="muted" src={video} className="md:w-[70vw] w-[80vw] md:block hidden" />
          {/* mobile */}
          <video 
            id='video-mobile1'
            x5-playsinline="true"
            playsinline="true"
            webkit-playsinline
            playsInline
            autoPlay="autoplay" loop="loop" muted src={videoMobile} className="md:w-[70vw] w-[80vw] md:hidden" />
          <script>
            document.getElementById('video-mobile1').play();
          </script>
        </div>

        <div className="flex md:flex-row flex-col md:w-[70vw] w-[80vw] mx-auto justify-between md:py-[5px] md:pt-[0vw] pt-[20vw]">
          <AnimationOnScroll animateIn="animate__fadeInLeftBig" delay={200} className="hidden md:block">
            <div className="flex flex-col justify-between h-[22vw]">
              <div className="flex flex-col text-[3vw]">
                <div>人工智能与感知</div>
              </div>
              <div className="flex flex-col text-[1.3vw] leading-[2.5vw] font-extralight">
                <div>赛恩领动通过数据闭环将收集到的车辆数据</div>
                <div>用于优化人工智能感知性能</div>
                <div>持续提高成像雷达产品竞争力</div>
              </div>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__fadeInLeftBig" delay={200} className="flex md:hidden justify-between w-[80vw]">
            <div className="flex flex-col justify-between md:h-[22vw] my-[2vw]">
              <div className="text-[5vw] pb-[6vw]">
                人工智能与感知
              </div>
              <div className="flex flex-col text-[3vw] font-extralight tracking-wide">
                <div className="mt-[1.5vw]">赛恩领动通过数据闭环</div>
                <div className="mt-[1.5vw]">将收集到的车辆数据</div>
                <div className="mt-[1.5vw]">用于优化人工智能感知性能</div>
                <div className="mt-[1.5vw]">持续提高成像雷达产品竞争力</div>
              </div>
            </div>

            <AnimationOnScroll animateIn="animate__fadeInDownBig" delay={200}>
              <img src={verticalBlock} alt="" className="w-[1vw] h-[16vw] md:mt-[20vw] mt-[22vw] ml-[0vw] md:mb-[0vw] mb-[3vw]" />
            </AnimationOnScroll>
          </AnimationOnScroll>

          <div className="flex md:w-[40vw] justify-center md:mt-[0vw] mt-[5vw]">
            <AnimationOnScroll animateIn="animate__fadeInRightBig" delay={200}>
              {/* pc */}
              <video autoPlay="autoplay" loop="loop" muted="muted" src={video2} className="md:w-[50vw] w-[80vw] md:max-h-[30vw] md:block hidden" />
              {/* mobile */}
              <video 
                id='video-mobile2'
                x5-playsinline="true"
                playsinline="true"
                webkit-playsinline
                playsInline
                autoPlay="autoplay" loop="loop" muted src={videoMobile2} className="md:w-[50vw] w-[80vw] md:max-h-[30vw] md:hidden"/>
              <script>
                document.getElementById('video-mobile2').play();
              </script>
            </AnimationOnScroll>
          </div>

        </div>

        <div className="hidden bg-cover bg-mainpage-background-2 mt-[15vw] md:flex flex-col pt-[5vw] pb-[6vw] items-center text-black">
          <div className="text-[2.6vw] pb-[5vw]"> 应用场景 </div>
          <div className="flex w-[70vw] justify-between">
            <div className="flex flex-col justify-between items-center">
              <img src={pic1} className="h-[8.6vw] pb-[1vw]" />
              <div className="text-[1.3vw] pb-[5vw]">自动驾驶</div>
              <img src={pic5} className="h-[8.3vw] pb-[1vw]" />
              <div className="text-[1.3vw]">机器人</div>
            </div>
            <div className="flex flex-col justify-between items-center">
              <img src={pic2} className="h-[8vw] pb-[1vw]" />
              <div className="text-[1.3vw] pb-[5vw]">ROBOTAXI</div>
              <img src={pic6} className="h-[7vw] pb-[1vw]" />
              <div className="text-[1.3vw]">飞行汽车</div>
            </div>
            <div className="flex flex-col justify-between items-center">
              <img src={pic3} className="h-[7.2vw] mt-[1vw] pb-[1.3vw]" />
              <div className="text-[1.3vw] pb-[5vw] pt-[0.6vw]">干线物流</div>
              <img src={pic7} className="h-[9.9vw] pb-[1vw]" />
              <div className="text-[1.3vw]">智能交通</div>
            </div>
            <div className="flex flex-col justify-between items-center">
              <img src={pic4} className="h-[7.2vw] mt-[1vw] pb-[1.3vw]" />
              <div className="text-[1.3vw] pb-[5vw]">无人配送</div>
              <img src={pic8} className="h-[8.6vw] pb-[0.2vw]" />
              <div className="text-[1.3vw]">生命体征检测</div>
            </div>
          </div>

        </div>

        <div className="flex bg-cover bg-mainpage-background-2 mt-[15vw] md:hidden flex-col pt-[8vw] pb-[6vw] items-center text-black">
          <div className="md:text-[2.6vw] text-[5vw] md:pb-[5vw] pb-[8vw] md:mt-[0vw] mt-[2vw]"> 应用场景 </div>
          <div className="flex flex-col md:w-[50vw] w-[65vw] justify-between flex-overlap md:text-[2vw] text-[3vw] font-light">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col justify-center items-center md:ml-[0vw] ml-[5vw]">
                <img src={pic1} className="md:h-[15vw] h-[18vw] pb-[1vw]" />
                <div className=" pb-[6vw] md:mt-[0vw] mt-[1.5vw]">自动驾驶</div>
              </div>
              <div className="flex flex-col justify-center items-center md:mr-[0vw] mr-[5vw]">
                <img src={pic2} className="md:h-[15vw] h-[16.5vw] pb-[1vw]" />
                <div className="pb-[6vw] md:mt-[0vw] mt-[2.5vw]">ROBOTAXI</div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col justify-center items-center md:ml-[0vw] ml-[4vw]">
                <img src={pic3} className="md:h-[12vw] h-[13.5vw] mt-[1vw] pb-[1vw]" />
                <div className=" pb-[7vw] pt-[0.6vw] md:mt-[0vw] mt-[1.5vw]">干线物流</div>
              </div>

              <div className="flex flex-col justify-center items-center md:mr-[0vw] mr-[4vw]">
                <img src={pic4} className="md:h-[12vw] h-[13.5vw] mt-[1.5vw] pb-[1vw]" />
                <div className=" pb-[7vw] md:mt-[0vw] mt-[2vw] md:ml-[0vw] ml-[1vw]">无人配送</div>
              </div>

            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col justify-center items-center md:ml-[0vw] ml-[5.5vw]">
                <img src={pic5} className="md:h-[13vw] h-[17vw] pb-[1vw]" />
                <div className=" pb-[5vw] md:mt-[0vw] mt-[2vw]">机器人</div>
              </div>

              <div className="flex flex-col justify-center items-center md:mr-[0vw] mr-[0vw]">
                <img src={pic6} className="md:h-[11vw] h-[13vw] md:ml-[0vw] ml-[5vw] pb-[1vw] md:mt-[0vw] mt-[3vw]" />
                <div className=" pb-[5vw] md:mt-[0vw] mt-[3vw] md:ml-[0vw] ml-[5vw]">飞行汽车</div>
              </div>

            </div>

            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col justify-center items-center md:ml-[0vw] ml-[2.5vw]">
                <img src={pic7} className="md:h-[14vw] h-[20vw] pb-[1vw]" />
                <div className=" pb-[5vw] md:mt-[0vw] mt-[1vw]">智能交通</div>
              </div>
              <div className="flex flex-col justify-center items-center md:mr-[5vw] mr-[6vw]">
                <img src={pic8} className="md:h-[13vw] h-[17vw] pb-[0.2vw] md:mt-[0vw] mt-[2vw]" />
                <div className=" pb-[5vw] md:mt-[0vw] mt-[2vw]">生命体征检测</div>
              </div>

            </div>
          </div>

        </div>
      </div>
      <Foot />
    </div>
  );
}
