import {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Alert from "../../components/Alert";

import {Pagination} from "antd";
import {SearchOutlined} from "@ant-design/icons";

import "./news.css";
import Foot from "./Foot";

import {baseServerUrl} from "../../utils/server.js";

const baseUrl = `${baseServerUrl}/api/article`;

const listWords = {
  0: "时间排序",
  1: "新闻资讯",
  2: "主题分享",
};
export default function News() {
  const [chooseType, setChooseType] = useState('0');
  const rotparams = useLocation();
  useEffect(() => {
    if (rotparams.search) {
      let params = new URLSearchParams(rotparams.search.split('?')[1]);
      if (params.get("cid")) {
        setChooseType(params.get("cid"))
      }
    }
  }, [rotparams.search]);

  const [show, setShow] = useState(false);
  const [type, setType] = useState("");

  const getCurrentColor = (item) => {
    if (chooseType === item) {
      return "text-sinpro-blue2 border-b-2 border-sinpro-blue2";
    }
    return "text-gray-400";
  };
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const pageSize = 12;

  const onChangePage = (_page, _pageSize) => {
    setPage(_page);
  };
  const [searchStr, setSearchStr] = useState("");
  let timer = useRef(null);
  const onSearch = (e) => {
    setSearchStr(e.target.value);
    setPage(1);
  };

  const [newsList, setNewsList] = useState([]);
  const getNewsListByType = (_type = 0) => {
    const params = {
      pageSize,
      cur: page,
    };
    if (searchStr) {
      params.keyword = searchStr;
    } else if (Number(_type) !== 0) {
      params.cid = _type;
    }
    axios
      .get(`${baseUrl}/search`, {
        params,
      })
      .then((res) => {
        const data = res.data.data;
        if (
          res.data.code !== 200 ||
          chooseType !== _type ||
          data.current !== page
        ) {
          return;
        }
        setNewsList(data.list);
        setMaxPage(data.count);
        console.log(data);
      });
  };
  useEffect(() => {
    clearTimeout(timer.current);
    if (searchStr) {
      timer.current = setTimeout(() => {
        getNewsListByType(chooseType);
      }, 500);
    } else {
      getNewsListByType(chooseType);
    }
  }, [chooseType, page, searchStr]);

  const [topArticle, setTopArticle] = useState([]);
  const getTopArticle = () => {
    axios.get(`${baseUrl}/top`).then((res) => {
      if (res.data.code !== 200) {
        return;
      }
      setTopArticle(res.data.data);
      console.log(res.data);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getTopArticle();
  }, []);

  return (
    <>
      <div className=" bg-cover text-black w-[100vw] mt-[-12vw] hidden md:block">
        <div className="flex flex-col items-end pt-[20vw] w-[70vw] mx-auto text-[2vw]">
          {topArticle.id ? (
            <div className="flex">
              <div className="w-1/2 p-4">
                <img
                  src={`${baseServerUrl}${topArticle.img}`}
                  alt=""
                  className="w-full h-auto rounded  aspect-[2/1] object-contain"
                />
              </div>
              <div className="w-1/2 p-4">
                <div className="mb-4">
                  <h1 className="text-4xl text-sinpro-blue2 font-bold">
                    {topArticle.createdAt.substring(8, 10)}
                  </h1>
                  <h2 className="text-sm mt-[-0.3vw]">
                    <span className="text-sinpro-blue2">
                      {topArticle.createdAt.substring(0, 7)}
                    </span>
                  </h2>
                </div>
                <div className="mb-4">
                  <h2 className="text-2xl text-sinpro-blue2">
                    {topArticle.title}{" "}
                  </h2>
                </div>
                <div className="flex mb-4">
                  <span className="w-[0.6vw] h-[0.6vw] bg-sinpro-blue2 mr-2"></span>
                  <span className="w-[0.6vw] h-[0.6vw] bg-sinpro-blue2 mr-2 opacity-80"></span>
                  <span className="w-[0.6vw] h-[0.6vw] bg-sinpro-blue2 mr-2 opacity-60"></span>
                  <span className="w-[0.6vw] h-[0.6vw] bg-sinpro-blue2 mr-2 opacity-40"></span>
                  <span className="w-[0.6vw] h-[0.6vw] bg-sinpro-blue2 opacity-20"></span>
                </div>
                <div className="mb-4 text-sm text-gray-400">
                  <p>{topArticle.description}</p>
                </div>
                <div>
                  <Link to={`/News/detail/${topArticle.cid}/${topArticle.id}`}>
                    <button
                      className="bg-sinpro-blue2 hover:bg-sinpro-blue2 text-white font-bold
                    py-2 px-4 rounded-full
                    text-sm"
                    >
                      点击详情
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className="flex flex-col pt-[2vw] pb-[2vw] w-[70vw] mx-auto text-[2vw]">
          <div className="flex justify-between flex-start pl-2">
            <div className="flex">
              {Object.keys(listWords).map((item) => {
                return (
                  <div
                    key={item}
                    className={`mr-[2vw] text-base cursor-pointer ${getCurrentColor(
                      item
                    )}`}
                    onClick={() => {
                      setChooseType(item);
                      setSearchStr("");
                      setPage(1);
                    }}
                  >
                    {listWords[item]}
                  </div>
                );
              })}
            </div>
            <div className="flex rounded border items-center px-2">
              <input
                type="search"
                className="h-[2vw] text-sm rounded outline-none"
                placeholder="输入搜索关键词"
                value={searchStr}
                onChange={onSearch}
              />
              <SearchOutlined className="text-gray-400 text-sm" />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2 mt-6">
            {newsList.map((item) => {
              return (
                <Link to={`/News/detail/${item.cid}/${item.id}`} key={item.id}>
                  <div className="listItem p-4 relative" key={item.id}>
                    <img
                      src={`${baseServerUrl}${item.img}`}
                      className="rounded  aspect-[2/1]"
                      alt=""
                    />
                    <p
                      className="text-left text-base mt-2 max-h-[4vw]
                    text-zinc-600 cursor-pointer
                  text-ellipsis overflow-hidden myEllipsis"
                    >
                      {item.title}
                    </p>
                    <div className="text-sm mt-2">
                      {item.updatedAt.substr(0, 10)}
                    </div>

                    <div className="overShow opacity-0 flex absolute bottom-2 right-2 text-xs text-zinc-400">
                      点击详情
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>

        <div className="flex justify-center pt-[1vw] pb-[2vw] w-[60vw] mx-auto text-[2vw] text-base text-gray-400">
          <Pagination
            defaultCurrent={1}
            current={page}
            total={maxPage}
            pageSize={pageSize}
            onChange={onChangePage}
            theme={{
              components: {
                Pagination: {
                  /* here is your component tokens */
                  // colorBorder: '#000'
                  itemActiveBg: '#000'
                },
              },
            }}
          />
        </div>
        <div className="border-t-2"></div>
        <Foot />
        <Alert
          show={show}
          handleShow={() => {
            setShow(false);
          }}
          type={type}
        />
      </div>

      {/* mobile */}
      <div className="bg-cover text-white w-[100vw] mt-[-10vw] md:hidden">
        <div className="flex flex-col items-end pt-[25vw] w-[90vw] mx-auto text-[2vw]">
          {topArticle.id ? (
            <div className="flex  flex-col">
              <div className="w-100 px-4 py-2">
                <img
                  src={baseServerUrl + topArticle.img}
                  alt=""
                  className="w-full h-auto rounded  aspect-[2/1] object-contain"
                />
              </div>
              <div className="w-100 px-4 py-0">
                <div className="mb-4">
                  <h1 className="text-4xl text-sinpro-blue2 font-bold">
                    {topArticle.createdAt.substring(8, 10)}
                  </h1>
                  <h2 className="text-sm mt-[-0.3vw]">
                    <span className="text-sinpro-blue2">
                      {topArticle.createdAt.substring(0, 7)}
                    </span>
                  </h2>
                </div>
                <div className="mb-4">
                  <h2 className="text-2xl text-sinpro-blue2">{topArticle.title}</h2>
                </div>
                <div className="flex mb-4">
                  <span className="w-[1.6vw] h-[1.6vw] bg-sinpro-blue2 mr-2"></span>
                  <span className="w-[1.6vw] h-[1.6vw] bg-sinpro-blue2 mr-2 opacity-80"></span>
                  <span className="w-[1.6vw] h-[1.6vw] bg-sinpro-blue2 mr-2 opacity-60"></span>
                  <span className="w-[1.6vw] h-[1.6vw] bg-sinpro-blue2 mr-2 opacity-40"></span>
                  <span className="w-[1.6vw] h-[1.6vw] bg-sinpro-blue2 opacity-20"></span>
                </div>
                <div className="mb-4 text-sm text-gray-400">
                  <p>{topArticle.description}</p>
                </div>
                <div>
                  <Link to={`/News/detail/${topArticle.cid}/${topArticle.id}`}>
                    <button
                      className="bg-sinpro-blue2 hover:bg-sinpro-blue2 text-white font-bold py-2 px-4 rounded-full
                      text-sm"
                    >
                      点击详情
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className="flex flex-col px-4 pt-[8vw] pb-[2vw] w-[90vw] mx-auto text-[2vw]">
          <div className="flex justify-between flex-start">
            <div className="flex">
              {Object.keys(listWords).map((item) => {
                return (
                  <div
                    key={item}
                    className={`mr-[4vw] last:mr-0 text-sm cursor-pointer ${getCurrentColor(
                      item
                    )}`}
                    onClick={() => {
                      setChooseType(item);
                      setSearchStr("");
                      setPage(1);
                    }}
                  >
                    {listWords[item]}
                  </div>
                );
              })}
            </div>
            <div className="flex rounded border items-center px-2 w-[25vw]">
              <input
                type="search"
                className="h-[5vw] text-[2vw] w-full text-black rounded outline-none"
                placeholder="输入搜索关键词"
                value={searchStr}
                onChange={onSearch}
              />
              <SearchOutlined className="text-gray-400 text-sm" />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-5 mt-4">
            {newsList.map((item) => {
              return (
                <Link to={`/News/detail/${item.cid}/${item.id}`} key={item.id}>
                  <div className="listItem relative" key={item.id}>
                    <img
                      src={baseServerUrl + item.img}
                      className="rounded w-full aspect-[2/1]"
                      alt=""
                    />
                    <p
                      className="text-left text-base mt-2
                      text-zinc-600 cursor-pointer
                    text-ellipsis overflow-hidden myEllipsis"
                    >
                      {item.title}
                    </p>
                    <div className="text-sm mt-1 text-gray-400">
                      {item.updatedAt.substr(0, 10)}
                    </div>

                    <div className="overShow opacity-0 flex absolute bottom-2 right-2 text-xs text-zinc-400">
                      点击详情
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>

        <div className="flex justify-center pt-[8vw] pb-[10vw] w-[90vw] mx-auto text-[2vw] text-base text-gray-400">
          <Pagination
            defaultCurrent={1}
            total={maxPage}
            pageSize={pageSize}
            onChange={onChangePage}
          />
        </div>

        <Foot />

        <Alert
          show={show}
          handleShow={() => {
            setShow(false);
          }}
          type={type}
        />
      </div>
    </>
  );
}
