import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useEffect } from 'react';
import verticalBlock from './img/verticalBlock.png';
import horizonBlock from './img/horizonBlock.png';
import RightToLeft from './img/RightToLeft.png';
import LeftToRight from './img/leftToRight.png';
import coreTech from './img/coretech2.jpg';
import SLAM from './img/SLAM.jpg';
import screen from './img/screen.png';
import upshadow from './img/upshadow.png';
import downshadow from './img/downshadow.png';
import pic1 from './img/pic1.png';
import pic2 from './img/pic2.png';
import pic3 from './img/pic3.png';
import pic4 from './img/pic4.png';
import Foot from '../../components/Foot';
import 'animate.css/animate.min.css';

import Foot2 from '../News/Foot';

export default function CoreTech() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="md:bg-techCore-background bg-products-background-mobile bg-cover text-white w-[100vw] mt-[-8vw] overflow-x-hidden">
        <div className="md:w-[70vw] w-[80vw] mx-auto">
          <div className="flex flex-row md:pt-[20vw] pt-[90vw] justify-center">
            <div className="flex flex-col items-start md:w-auto w-[80vw]">
              <AnimationOnScroll animateIn="animate__fadeInDown " delay={200}>
                <div className="font-extrabold text-[5vw] whitespace-pre md:block hidden">PERCEPTION   ·   FUTURE</div>
                <div className="font-extrabold text-[8vw] whitespace-pre tracking-widest md:hidden">PERCEPTION</div>
                <div className="font-extrabold text-[8vw] whitespace-pre  tracking-widest md:hidden mt-[-2vw]">FUTURE</div>

              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInDown" delay={300}>
                <div className="font-light md:text-[3vw] text-[8vw] whitespace-pre tracking-wider mt-[-1vw]">感知 · 未来</div>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInDown" delay={400}>
                <div className="font-light text-[1.4vw] whitespace-pre tracking-wider mt-[3vw] md:block hidden">我们是赛恩领动</div>
                <div className="text-[4vw] whitespace-pre tracking-wider font-extralight mt-[13vw] md:hidden">我们是</div>
                <div className="text-[4vw] whitespace-pre tracking-wider font-extralight md:hidden">赛恩领动</div>

              </AnimationOnScroll>
            </div>
            <AnimationOnScroll animateIn="animate__fadeInUp" delay={200}>
              <img src={verticalBlock} alt="" className="md:w-[0.5vw] w-[1vw] md:h-[13vw] h-[18vw] md:mt-[2vw] mt-[60vw] ml-[2vw]" />
            </AnimationOnScroll>
          </div>

          <div className="flex md:pt-[23vw] pt-[60vw] mx-auto justify-center items-center md:x-auto x-[80vw] md:pb-[0vw] pb-[5vw]">
            <img src={LeftToRight} alt="" className="md:w-[10vw] w-[20vw] md:h-[0.25vw] h-[0.5vw]" />
            <div className=" md:text-[2.5vw] text-[5vw] whitespace-pre tracking-wider px-[2vw] md:ml-[0vw] ml-[5vw] md:mr-[0vw] mr-[5vw] md:mt-[0vw] mt-[0vw]">系统性能</div>

            <img src={RightToLeft} alt="" className="md:w-[10vw] w-[20vw] md:h-[0.25vw] h-[0.5vw]" />

          </div>

          <div className="flex md:flex-row flex-col justify-between md:pt-[8vw] pt-[10vw] md:w-auto w-[80vw]">
            <AnimationOnScroll animateIn="animate__fadeInLeft" delay={200} animateOnce>
              <div className="md:flex flex-col md:text-[1.2vw] leading-relaxed tracking-wide border-2vw font-extralight hidden">

                <div className="ml-[0vw] mt-[4vw] leading-[2.2vw]">赛恩领动运用新一代技术研发生产的车规级成像雷达</div>
                <div className="ml-[0vw] leading-[2.2vw]">拥有120°超广水平视场角，最长探测距离达300m以上</div>
                <div className="ml-[0w] leading-[2.2vw]">全天候全工况提供探测物体的距离、速度、角度以及高度等信息</div>
                <div className="ml-[0vw] leading-[2.2vw]">目标输出接口支持最新ISO23150国际标准</div>
                <div className="ml-[0vw] leading-[2.2vw]">可与其他传感器融合实现高级别自动驾驶功能</div>

                <img src={horizonBlock} alt="" className="w-[15vw] h-[0.5vw] ml-[0vw] mt-[7.7vw]" />
              </div>

              <div className="md:hidden flex flex-col text-[3.5vw] leading-[5vw] tracking-normal font-extralight">

                <div className="my-[1vw]">赛恩领动运用新一代技术研发生产的</div>
                <div className="my-[1vw]">车规级成像雷达，</div>
                <div className="my-[1vw]">拥有120°超广水平视场角，</div>
                <div className="my-[1vw]">最长探测距离达300m以上，</div>
                <div className="my-[1vw]">全天候全工况提供探测物体的</div>
                <div className="my-[1vw]">距离、速度、角度以及高度等信息。</div>
                <div className="my-[1vw]">目标输出接口支持最新ISO23150国际标准，</div>
                <div className="my-[1vw]">可与其他传感器融合实现高级别自动驾驶功能。</div>

                <img src={horizonBlock} alt="" className="md:w-[15vw] w-[20vw] md:h-[0.5vw] h-[1vw] ml-[0vw] mt-[7.7vw]" />
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInRight" delay={200} animateOnce className='md:mt-[0vw] mt-[10vw]'>
              <img src={screen} alt="" className="md:w-[33vw] w-[80vw] md:mr-[-1vw] mr-[-2vw] md:ml-[0vw] ml-[0vw] md:mt-[-3vw] mt-[20vw] md:mb-[0vw] mb-[0vw]" />
            </AnimationOnScroll>
          </div>

          <div className="flex flex-col md:w-[70vw] w-[80vw] md:mt-[15vw] mt-[40vw] mx-auto md:text-[1.2vw] text-[3vw]">

            <div className=" md:text-[2vw] text-[5vw] md:mt-[2vw] mt-[0vw] whitespace-pre tracking-wider self-center">技术参数</div>
            <img src={upshadow} alt="" className="md:mt-[0vw] mt-[10vw]" />

            <AnimationOnScroll animateIn="animate__fadeIn" delay={800} animateOnce>
              <div className="flex flex-around md:flex-row flex-col  md:mb-[0.4vw] mb-[5vw] md:mt-[0vw] mt-[5vw] font-extralight leading-relaxed items-center">
                <div className="w-[40vw] md:ml-[10vw] mt-[2.5vw] md:text-left text-center md:font-extralight font-light">最大探测距离</div>
                <div className="mt-[2.5vw]">
                  {' '}
                  {'≥'}
                  {' '}
                  300m
                </div>
              </div>
            </AnimationOnScroll>

            <div className="bg-blue-400 font-thin opacity-30  md:w-[70vw] w-[80vw] md:h-[0.15vw] h-[0.3vw] md:my-[1vw] my-[3vw] self-center" />
            <AnimationOnScroll animateIn="animate__fadeIn" delay={800} animateOnce>
              <div className="flex flex-around md:flex-row flex-col   md:mb-[0.4vw] mb-[5vw] md:mt-[0vw] mt-[5vw] font-extralight leading-relaxed items-center">
                <div className="w-[40vw] md:ml-[10vw] text-center md:text-left text-center md:font-extralight font-light">水平 FOV</div>
                <div className="md:mt-[0vw] mt-[2.5vw]">
                  {'>'}
                  {' '}
                  120°
                </div>
              </div>
            </AnimationOnScroll>
            <div className="bg-blue-400 font-thin opacity-30  md:w-[70vw] w-[80vw] md:h-[0.15vw] h-[0.3vw] md:my-[1vw] my-[3vw] self-center" />

            <AnimationOnScroll animateIn="animate__fadeIn" delay={800} animateOnce>
              <div className="flex flex-around md:flex-row flex-col   md:mb-[0.4vw] mb-[5vw] md:mt-[0vw] mt-[5vw] font-extralight leading-relaxed items-center">
                <div className="md:w-[40vw] w-[60vw] md:ml-[10vw] text-center md:text-left text-center md:font-extralight font-light">
                  水平角分辨率/-3dB Beamwidth
                </div>
                <div className="md:mt-[0vw] mt-[2.5vw]">
                  {' '}
                  {'<'}
                  {' '}
                  1°
                </div>
              </div>
            </AnimationOnScroll>
            <div className="bg-blue-400 font-thin opacity-30 md:w-[70vw] w-[80vw] md:h-[0.15vw] h-[0.3vw] md:my-[1vw] my-[3vw] self-center" />

            <AnimationOnScroll animateIn="animate__fadeIn" delay={800} animateOnce>
              <div className="flex flex-around md:flex-row flex-col   md:mb-[0.4vw] mb-[5vw] md:mt-[0vw] mt-[5vw] font-extralight leading-relaxed items-center">
                <div className="w-[40vw] md:ml-[10vw] text-center md:text-left text-center md:font-extralight font-light">测速范围</div>
                <div className="md:mt-[0vw] mt-[2.5vw]">
                  -400 km/h ~ 200 km/h
                </div>
              </div>
            </AnimationOnScroll>
            <div className="bg-blue-400 font-thin opacity-30 md:w-[70vw] w-[80vw] md:h-[0.15vw] h-[0.3vw] md:my-[1vw] my-[3vw] self-center" />

            <AnimationOnScroll animateIn="animate__fadeIn" delay={800} animateOnce>
              <div className="flex flex-around md:flex-row flex-col   md:mb-[0.4vw] mb-[5vw] md:mt-[0vw] mt-[5vw] font-extralight leading-relaxed items-center">
                <div className="w-[40vw] md:ml-[10vw] text-center md:text-left text-center md:font-extralight font-light">功能安全等级</div>
                <div className="md:mt-[0vw] mt-[2.5vw]">
                  ASILB( D )
                </div>
              </div>
            </AnimationOnScroll>

            <img src={downshadow} alt="" className="mt-[3vw]" />
          </div>

          <div className="flex-col md:w-[70vw] w-[80vw] mx-auto md:mt-[11vw] mt-[25vw] flex items-center text-black">
            <div className="text-[5vw] md:text-[2vw] whitespace-pre tracking-wider self-center ">技术优势</div>
            <div className="flex justify-between w-[70vw] py-[5vw] flex-col md:flex-row md:mt-[0vw] mt-[2vw]">
              <div className="flex flex-col items-center justify-between">
                <img src={pic2} alt="" className="md:w-[17vw] w-[40vw] mt-[0vw] mb-[1vw]" />
                <div className="md:text-[1.4vw] text-[3.5vw] mt-[1vw] md:mb-[0vw] mb-[7vw]">超分辨测角</div>
              </div>
              <div className="flex flex-col items-center justify-between">
                <img src={pic1} alt="" className="md:w-[9vw] w-[21vw] mb-[1vw] mt-[1vw]" />
                <div className="md:text-[1.4vw] text-[3.5vw] mt-[3vw] md:mb-[0vw] mb-[7vw]">抗干扰技术</div>
              </div>

              <div className="flex flex-col items-center justify-between">
                <img src={pic3} alt="" className="md:w-[9.6vw] w-[24vw] mb-[1vw] mt-[1.9vw]" />
                <div className="md:text-[1.4vw] text-[3.5vw] mt-[3vw] md:mb-[0vw] mb-[7vw]">隐藏目标识别</div>
              </div>

              <div className="flex flex-col items-center justify-between">
                <img src={pic4} alt="" className="md:w-[13.5vw] w-[32vw] mb-[1vw] " />
                <div className="md:text-[1.4vw] text-[3.5vw] mt-[0.5vw] md:mb-[0vw] mb-[7vw]">AI感知</div>
              </div>
            </div>
          </div>

          <div className="flex pt-[15vw] mx-auto justify-center items-center">

            <img src={LeftToRight} alt="" className="md:w-[10vw] md:h-[0.25vw] w-[20vw] h-[0.5vw]" />
            <div className=" md:text-[2.5vw] text-[5vw] whitespace-pre tracking-wider px-[2vw]  md:ml-[0vw] ml-[5vw] md:mr-[0vw] mr-[5vw]">感知算法</div>

            <img src={RightToLeft} alt="" className="md:w-[10vw] md:h-[0.25vw] w-[20vw] h-[0.5vw]" />

          </div>

          <div className="flex md:w-[70vw] w-[80vw] justify-around mt-[8vw] md:flex-row flex-col">
            <AnimationOnScroll animateIn="animate__slideInLeft" delay={200} animateOnce>
              <img src={coreTech} alt="" className="md:w-[40vw] md:h-[25vw] w-[60vw]" />
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__slideInRight" delay={200} animateOnce>
              <div className="flex flex-col md:text-[1.15vw] text-[3vw] items-end leading-loose font-extralight mt-[15vw]">
                <div>运用新一代技术解决了</div>
                <div>目标高度的探测和静态障碍物的检测与分类</div>
                <div className="mt-[4vw]">将人工智能技术应用到毫米波雷达感知算法中</div>
                <div>大幅度提升对障碍物检测的准确率</div>

                <div className="bg-sinpro-blue md:w-[12vw] w-[20vw] md:h-[0.5vw] h-[1vw] md:mt-[1vw] mt-[5vw] shadow" />
              </div>
            </AnimationOnScroll>
          </div>

          <div className="flex md:w-[70vw] w-[80vw] pt-[10vw] pb-[5vw]">
            <AnimationOnScroll animateIn="animate__slideInLeft" delay={200} animateOnce>
              <div className="flex md:w-[30vw] w-[20vw] h-[25vw] flex-col bg-slam-bkg pt-[4vw] pl-[4vw] mb-[5vw] font-light">
                <div className="text-sinpro-blue md:text-[1.5vw] text-[3vw] md:ml-[0vw] ml-[-1vw] mb-[2vw] font-normal">雷达SLAM</div>
                <div className="text-black text-[1.15vw] hidden md:block">基于高密度点云以及雷达SLAM算法 ,</div>
                <div className="text-black mb-[4vw] text-[1.15vw] hidden md:block">实现建图与精准定位</div>
                <div className="bg-sinpro-blue h-[0.7vw] w-[6.5vw]" />
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__slideInRight" delay={200} animateOnce>
              <img src={SLAM} alt="" className="md:w-[40vw] w-[60vw] mt-[10vw]" />
            </AnimationOnScroll>
          </div>
          <div className="fmd:hidden text-[3vw] leading-loose align-right text-right font-extralight mt-[2vw]">
            <div className="text-white  md:hidden">基于高密度点云以及雷达SLAM算法</div>
            <div className="text-white mb-[4vw]  md:hidden">实现建图与精准定位</div>
          </div>
        </div>
      </div>
      <Foot2 />
    </>
  );
}
