
import { Link } from 'react-router-dom';
import qrcode from './img/qrcode.png';
import mobileQrcode from './img/mobileQrcode.png';

export default function Foot() {
  return (
    <>
      <div className="flex justify-between pt-[1.5vw] w-[100vw] mx-auto pb-[3vw] items-center hidden md:block">
        <div className="w-[70vw] mx-auto">
          <div className='flex justify-end font-bold mb-[1vw] text-[1.15vw]'>
            <Link to="/MainPage">首页</Link>
            <Link className='ml-[3vw]' to="/CoreTech">产品与技术</Link>
            <Link className='ml-[3vw]' to="/News">新闻中心</Link>
            <Link className='ml-[3vw]' to="/About">关于我们</Link>
          </div>
          <div className="flex items-start items-center justify-items-stretch">
            <img src={qrcode} className="mt-[0.1vw]" height="90" width="90" alt='' />
            <div className="text-sm ml-[1vw] w-[100%] text-[0.8vw]">
              <div>赛恩领动（上海）智能科技有限公司</div>
              <div className="md:mt-[0.2vw]">SINPRO Intelligent Technology(shanghai) Co., Ltd.</div>
              <div className="mt-[0.8vw] flex justify-between">
                <span>电话: 021-68586667</span>
                <span>商务合作: contactus@sinpro.ai</span>
                <span>简历投递: hr@sinpro.ai</span>
              </div>
            </div>
          </div>
          <div className="text-[3vw] md:text-[0.8vw] text-right text-gray-400 flex justify-between mt-4">
            <div>沪ICP备2022026014号-1</div>
            <div>沪公网安备 31011502019221号 赛恩领动（上海）智能科技有限公司 &copy;2022 SINPRO. All rights reserved</div>
          </div>
        </div>
      </div>
      {/* {mobile} */}
      <div className='md:hidden flex flex-col px-4 pt-[6vw] w-[90vw] mx-auto pb-[3vw] items-center border-t-2 text-black'>
        <div className="flex flex-col items-center">
          <img src={mobileQrcode} className="w-[25vw] h-[25vw] mt-[0.1vw] " alt='' />
          <div className="text-[3vw] md:text-[0.8vw] text-center">
            <div className="mt-4">赛恩领动（上海）智能科技有限公司</div>
            <div>SINPRO Intelligent Technology(shanghai) Co., Ltd.</div>
            <div className="mt-4">电话:021-68586667</div>
            <div>商务合作:contactus@sinpro.ai</div>
            <div>简历投递:hr@sinpro.ai</div>
          </div>
        </div>
        <div className="text-[3vw] md:text-[0.8vw] text-center mt-4 text-gray-400">
          <div>沪ICP备2022026014号-1</div>
          <div>沪公网安备 31011502019221号</div>
          <div>赛恩领动(上海)智能科技有限公司 ©2022 SINPRO.</div>
          <div>All rights reserved</div>
        </div>
      </div>
    </>
  );
}
