import { useState, useEffect } from 'react';
import axios from 'axios';
import leftArrow from './img/leftArrow.png';
import lightlogo from './img/light-logo.png';
import Alert from '../../components/Alert';

const baseUrl = 'http://47.243.186.165/api/contact';

export default function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [show, setShow] = useState(false);
  const [type, setType] = useState('');

  const handleClick = () => {
    if (name === '' || email === '' || title === '') {
      setShow(true);
      setType('necessary');
    } else if (!emailCheck()) {
      setShow(true);
      setType('email');
    } else {
      axios.post(baseUrl, {
        name,
        email,
        cellphone: phone,
        title,
        content,
      });
      setShow(true);
      setName('');
      setEmail('');
      setPhone('');
      setTitle('');
      setContent('');
      setType('success');
    }
  };

  const emailCheck = () => {
    //get email address content
    var email = document.getElementById("email");
    //email address reg
    var reg = /^([a-zA-Z0-9]+[_|\-|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/gi;
    //validate address
    if (!reg.test(email.value)) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-contact-background bg-cover text-white w-[100vw] mt-[-10vw] hidden md:block">
        <div className="flex flex-col items-end pt-[20vw] w-[70vw] mx-auto text-[2vw]">

          <div>欢迎留下您的联系方式</div>
          <div className="pb-[2vw]">我们将提供专业、周到的产品咨询服务</div>

          <div className="flex justify-between h-[3vw]">
            <div className="input-box text-black bg-white text-[1vw] flex items-center font-bold px-[0.5vw] w-[25vw]">
              <span className="prefix whitespace-pre">
                {' '}
                姓   名
                <span className="text-red-600">*</span>
              </span>
              ：
              <input type="text" className="outline-none overflow-hidden w-[20vw]" value={name} onChange={(e) => { setName(e.target.value); }} />
            </div>

            <div className="input-box text-black bg-white text-[1vw] flex items-center font-bold px-[0.5vw] ml-[2vw] w-[25vw]">
              <span className="prefix whitespace-pre">
                {' '}
                电子邮箱
                <span className="text-red-600">*</span>
              </span>
              ：
              <input id="email" type="text" className="outline-none overflow-hidden w-[18vw]" value={email} onChange={(e) => { setEmail(e.target.value); }} />
            </div>
          </div>

          <div className="flex justify-between h-[3vw] mt-[1vw]">
            <div className="input-box text-black bg-white text-[1vw] flex items-center font-bold px-[0.5vw] w-[25vw]">
              <span className="prefix whitespace-pre">
                {' '}
                手   机
                {' '}
                <span className="text-red-600"> </span>
              </span>
              ：
              <input type="tel" className="outline-none overflow-hidden w-[20vw]" value={phone} onChange={(e) => { setPhone(e.target.value); }} />
            </div>

            <div className="input-box text-black bg-white text-[1vw] flex items-center font-bold px-[0.5vw] ml-[2vw] w-[25vw]">
              <span className="prefix whitespace-pre">
                {' '}
                标    题
                <span className="text-red-600">*</span>
              </span>
              ：
              <input type="text" className="outline-none overflow-hidden w-[20vw]" value={title} onChange={(e) => { setTitle(e.target.value); }} />
            </div>
          </div>

          <div className="col-span-2 block w-[52vw] text-black text-[1vw] relative pt-[1vw] ">
            <div className="font-bold top-[2vw] left-[0.9vw] absolute
                         "
            >
              {' '}
              内
              {' '}
              <span className="ml-[0.4vw]" />
              {' '}
              容
              {' '}
              <span className="ml-[0.3vw]" />
              {' '}
              ：

            </div>
            <textarea
              id="message"
              rows="6"
              className="col-span-2 block
                         pl-[5vw] pt-[1vw] text-[1vw] w-full outline-none resize-none"
              placeholder=""
              value={content}
              onChange={(e) => {
                setContent(e.target.target);
              }}
            />
          </div>

          <div
            className="bg-yellow-500 hover:bg-yellow-700 mt-[1vw] text-center pt-[0.5vw] pr-[1vw]
              text-white text-[1vw] md:text-[1vw] md:h-[2.5vw] md:w-[8vw] w-[50px] h-[30px] relative"
            onClick={handleClick}
          >
            <span className="align-center tracking-[1vw]">发送</span>
            <img src={leftArrow} alt="" className="w-[1vw] absolute right-[1vw] top-[1vw]" />
          </div>

        </div>

        <div className="flex justify-between pt-[10vw] w-[70vw] mx-auto pb-[3vw] items-center">
          <div className="flex flex-col items-start text-[1vw]">
            <div className="text-[2vw] pb-[1vw]">赛恩领动（上海）智能科技有限公司</div>
            <div>电话 : 021-68586667 </div>
            <div>邮箱 : contactus@sinpro.ai</div>
            {/* <div>地址 : 上海市浦东新区荣盛路88号盛大天地源创谷1号楼902</div> */}
          </div>
          <img src={lightlogo} alt="" className="h-[5vw]" />
        </div>
        <Alert
          show={show}
          handleShow={() => { setShow(false); }}
          type={type}
        />
      </div>

      {/* mobile */}
      <div className="bg-contact-background-mobile bg-cover text-white w-[100vw] mt-[-10vw] md:hidden">
        <div className="flex flex-col pt-[50vw] w-[80vw] mx-auto text-[5vw] font-extralight">
          <div>欢迎留下您的联系方式</div>
          <div>我们将提供专业、周到的</div>
          <div className="mb-[15vw]">产品咨询服务</div>

          <div className="input-box text-black bg-white text-[3.5vw] flex items-center font-normal px-[0.5vw] w-[50vw] h-[9vw] mt-[4vw] mb-[4vw] mb-[6vw]">
            <span className="prefix whitespace-pre">
              {' '}
              姓   名
              <span className="text-red-600">*</span>
            </span>
            ：
            <input type="text" className="outline-none overflow-hidden w-[30vw]" value={name} onChange={(e) => { setName(e.target.value); }} />
          </div>

          <div className="input-box text-black bg-white text-[3.5vw] flex items-center font-normal px-[0.5vw] w-[50vw] h-[9vw] mb-[4vw] mb-[6vw]">
            <span className="prefix whitespace-pre">
              {' '}
              电子邮箱
              <span className="text-red-600">*</span>
            </span>
            ：
            <input id='email' type="text" className="outline-none overflow-hidden w-[28vw]" value={email} onChange={(e) => { setEmail(e.target.value); }} />
          </div>

          <div className="input-box text-black bg-white text-[3.5vw] flex items-center font-normal px-[0.5vw] w-[80vw] h-[9vw] mb-[4vw] mb-[6vw]">
            <span className="prefix whitespace-pre">
              {' '}
              手   机
              {' '}
              <span className="text-red-600"> </span>
            </span>
            ：
            <input type="tel" className="outline-none overflow-hidden w-[60vw]" value={phone} onChange={(e) => { setPhone(e.target.value); }} />
          </div>

          <div className="input-box text-black bg-white text-[3.5vw] flex items-center font-normal px-[0.5vw] w-[80vw] h-[9vw] mb-[4vw] mb-[6vw]">
            <span className="prefix whitespace-pre">
              {' '}
              标   题
              <span className="text-red-600">*</span>
            </span>
            ：
            <input type="text" className="outline-none overflow-hidden w-[60vw]" value={title} onChange={(e) => { setTitle(e.target.value); }} />
          </div>

          <div className="col-span-2 block w-[80vw] text-black text-[3.5vw] font-normal relative pt-[1vw] bg-white ">
            <div className="font-normal top-[2vw] left-[0.9vw] absolute
                         "
            >
              <span className="ml-[0.5vw]">内</span>
              <span className="ml-[0.2vw]" />
              <span className="ml-[2.1vw]">容 </span>
              <span className="ml-[1.1vw]">：</span>
            </div>
            <textarea
              id="message"
              rows="6"
              className="col-span-2 block
                         pl-[15vw] pt-[1vw] text-[3vw] w-full outline-none resize-none"
              placeholder=""
              value={content}
              onChange={(e) => {
                setContent(e.target.target);
              }}
            />
          </div>

          <div
            className="bg-yellow-500 hover:bg-yellow-700 text-center pr-[1vw] mt-[12vw] md:mt-[5vw]
              text-white text-[3.5vw] md:text-[1vw] md:h-[2.5vw] h-[7vw] md:w-[8vw] w-[25vw] py-[1vw] self-end relative"
            onClick={handleClick}
          >
            <span className="align-center tracking-[1vw]">发 送</span>
            <img src={leftArrow} alt="" className="w-[3vw] absolute md:right-[1vw] right-[2vw] md:top-[1.25vw] top-[2.3vw]" />
          </div>

        </div>

        <div className="flex flex-col items-end text-[1vw] w-[80vw] mx-auto py-[5vw] pb-[10vw]">
          <div className="text-[4.5vw] pb-[1vw] font-extralight">赛恩领动（上海）智能科技有限公司</div>
          <div className="text-[4vw] pb-[1vw] font-extralight">电话 : 021-68586667 </div>
          <div className="text-[4vw] pb-[1vw] font-extralight">邮箱 : contactus@sinpro.ai</div>
        </div>

        <Alert
          show={show}
          handleShow={() => { setShow(false); }}
          type={type}
        />
      </div>
    </>
  );
}
