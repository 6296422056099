import {
  About,
  AboutEn,
  Contact,
  CoreTech,
  MainPage,
  News,
  NewsDetail,
} from "../pages";

const routes = [
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/About",
    component: About,
  },
  {
    path: "/MainPage-en",
    component: AboutEn,
  },
  {
    path: "/coreTech",
    component: CoreTech,
  },
  {
    path: "/mainPage",
    component: MainPage,
  },
  {
    path: "/News",
    component: News,
  },
  {
    path: "/News/detail/:cid/:id",
    component: NewsDetail,
  },
  {
    path: "/",
    component: MainPage,
  },
];

export default routes;
