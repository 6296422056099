/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import routes from "./utils/routes.js";
import "animate.css/animate.min.css";

function App() {
  return (
    <BrowserRouter>
      <div className="relative">
        <Header />
        <Routes>
          {routes.map((route) => (
            <Route
              path={route.path}
              element={<route.component />}
              key={route.path}
            />
          ))}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
