import {useEffect, useRef, useState} from 'react';
import 'animate.css/animate.min.css';
import './lineAnimate.css';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {ReactComponent as Line2} from './img/svgs/line2.svg';
import {ReactComponent as Line4} from './img/svgs/line4.svg';
import {Carousel, Slider} from 'antd';
import star1 from './img/star1.png';
import star2 from './img/star2.png';
import pic1 from './img/pic1.png';
import pic2 from './img/pic2.png';
import pic3 from './img/pic3.png';
import pic4 from './img/pic4.png';
import pic5 from './img/pic5.png';
import lights from './img/lights.png';
import Foot from '../News/Foot';

import CarPic from './img/car.png'
import his1 from './img/history/his1.png'
import his2 from './img/history/his2.png'
import his4 from './img/history/his3.png'
import his3 from './img/history/his4.png'
import his5 from './img/history/his5.png'

import advantage1 from './img/advantage1.png'
import advantage2 from './img/advantage2.png'
import advantage3 from './img/advantage3.png'

import backToTop from './img/backToTop.png'
import { VerticalAlignTopOutlined } from '@ant-design/icons';

const marks = {
  10: {
    style: {
      color: '#0000',
    },
    label: '2023',
  },
  30: {
    style: {
      color: '#0000',
    },
    label: '2023',
  },
  50: {
    style: {
      color: '#0000',
    },
    label: '2023',
  },
  70: {
    style: {
      color: '#0000',
    },
    label: '2023',
  },
  90: {
    style: {
      color: '#0000',
    },
    label: '2023',
  },
};
export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const carouselEL = useRef(null);
  const carouselEL2 = useRef(null);
  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(0);

  const onScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className="md:bg-about-background bg-about-background-mobile bg-cover bg-no-repeat md:bg-cover bg-cover text-white w-[100vw] md:min-h-[287vw] overflow-x-hidden">
        <div className="flex px-auto w-[100vw] mx-auto flex-col">
          <div className="flex md:w-[70vw] w-[80vw] md:mt-[-5vw] mx-auto justify-between">
            {/* mobile */}
            <div
              animateIn="animate__drawPath"
              duration={0.1}
              className="md:hidden w-[10vw] mt-[7.5vw] ml-[-12vw]"
              animateOnce
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.34 278.2" width="25vw">
                <path fill="none"
                      stroke="white"
                      strokeMiterlimit={10}
                      strokeWidth="3.5px"
                      d="M93.68,47V218s-.84,30.51,33,33"/>
              </svg>
            </div>

            {/* pc version */}
            <div
              animateIn="animate__drawPath"
              duration={0.1}
              className="w-[5vw] h-[10vw] mt-[8.5vw] ml-[-1vw] hidden md:block"
              animateOnce
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.3vw"
                viewBox="0 0 284 278.2"
              >
                <path
                  fill="none"
                  stroke="white"
                  strokeMiterlimit={10}
                  strokeWidth="6px"
                  d="M36,47V139.1s-.68,56.15,33,83,63,31.46,77,31.46h84.67"
                />
              </svg>
            </div>


            <div
              className="flex flex-col md:pt-[15vw] pt-[30vw] ml-[3.5vw] md:w-[45vw] w-[70vw]  flex-2"
              // animateIn="animate__fadeIn"
              // animateOnce
            >
              <div className="text-[3.5vw] mt-[-2vw] ml-[5vw] font-extralight hidden md:block">
                关于赛恩领动 sinPro
              </div>

              <div className="text-[6vw] mt-[5vw] ml-[-0.5vw] font-extralight md:hidden">
                <div className="">关于赛恩领动</div>
                <div>sinPro</div>
              </div>

              <div className="flex flex-col md:text-[1.3vw] text-sm md:mt-[6vw] mt-[6vw] md:ml-[-8vw] ml-[-3.5vw] font-light md:leading-[2.8vw] leading-[6vw] pr-2">
                <div className="md:w-2/3">赛恩领动成立于2021年，是致力于车规级4D成像雷达感知方案规模化量产化落地的成长型高科技企业。业务范围包括研发4D成像雷达智能硬件、传感器算法及软件，以及基于人工智能的感知产品。
                </div>
                <div className='mt-4 md:w-2/3'>团队拥有完全自主创新的核心算法能力，通过解决典型智能驾驶场景痛点，为客户提供自动驾驶系统感知、多传感器融合及系统功能的解决方案并推动量产落地，全面赋能智能驾驶安全与舒适性的提升。
                </div>
              </div>
            </div>

            <div
              // animateIn="animate__drawPath2"
              // duration={0.1}
              className="w-[25vw] h-[25vw] pt-[12vw] mr-[-9.5vw] mt-[-1vw] hidden md:block"
              // animateOnce
            >
              <Line2 />
            </div>

            <div
              // animateIn="animate__drawPath2"
              // duration={0.1}
              className="mt-[-65.5vw] ml-[55vw] md:hidden absolute"
              // animateOnce
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.49 631.6" width="35vw" height="350vw">
                <path fill="none"
                      stroke="white"
                      strokeMiterlimit={10}
                      strokeWidth="1.5px"
                      d="M23.89,24.13h35s20.33-1.76,35.86,13.2S108.88,73,108.89,86.15s0,536.8,0,236.8"/>
              </svg>
            </div>
          </div>

          {/* second */}
          <div className="flex md:w-[70vw] w-[80vw] mx-auto justify-around relative mt-[2.4vw]">
            <div
              // animateIn="animate__drawPath3"
              // duration={0.1}
              className="w-[30vw] top-[5vw] left-[-9vw] absolute"
              // animateOnce
            >
              {/* <Line3/> - pc */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 551.89 1589.39"
                height="80vw"
                className='hidden md:block'
              >
                <path
                  fill="none"
                  stroke="white"
                  strokeMiterlimit={10}
                  strokeWidth="4px"
                  d="M417.23,179.33H159.75s-43.32,5.71-67.09,37.89-25.22,64.15-25.22,64.15v1093s-9.15,127.74,124,134.7"
                />
              </svg>

              {/* <Line3/> - mobile */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 140 556.69"
                height="170vw"
                className='md:hidden ml-[-10.5vw] mt-[5vw]'
              >
                <path
                  fill="none"
                  stroke="white"
                  strokeMiterlimit={10}
                  strokeWidth="1.3px"
                  d="M81.59,23.35S60.42,22.17,60.42,45.18v456S57,532.92,86.89,532.64"
                />
              </svg>
            </div>

            <div
              className="flex flex-col items-end
            pt-[11.5vw]  w-[100vw]"
              // animateIn="animate__fadeIn animate__delay-3s"
              // animateOnce
            >
              <div className="text-[3.5vw] font-extralight ml-[-4vw] hidden md:block">
                <span className="tracking-widest">sinPro</span>
                核心技术与竞争优势
              </div>

              <div className="mt-[0vw] text-[5.5vw] font-extralight ml-[0vw] mr-[8vw] md:hidden w-[80vw] flex flex-col justify-center items-end">
                {/* <div></div> */}
                <div>sinPro 核心技术与竞争优势</div>
              </div>

              {/* pc */}
              <div className='relative w-[75vw] h-[53vw] hidden md:block'>
                <div className='absolute left-[0vw] top-[20vw]'>
                  <div className='text-[1.5vw] text-bold'>特有的波形波束设计及创新</div>
                  <div className='text-[1vw]'>空间与多普勒联合变换的方法 SDJT</div>
                  <img src={advantage1} alt="" className="mt-[2vw] w-[20vw]"  />
                </div>
                <div className='absolute left-[28vw] top-[10vw]'>
                  <img src={advantage2} alt="" className="mb-[2vw] w-[20vw]"  />
                  <div className='text-[1.5vw] text-bold'>拥有 DSP 及核心算法自研能力</div>
                  <div className='text-[1vw]'>语义级别的扩展目标追踪能力</div>
                </div>
                <div className='absolute left-[53vw] top-[23vw]'>
                  <div className='text-[1.5vw] text-bold'>丰富的雷达量产经验</div>
                  <div className='text-[1vw]'>针对电磁波数据特性优化的 AI</div>
                  <img src={advantage3} alt="" className="mt-[2vw] w-[20vw]" />
                </div>
              </div>
              {/* mobile */}
              <div className='relative w-[82vw] md:hidden h-[130vw]'>
                <div className='mt-8 absolute flex justify-items-end right-0'>
                  <div className='w-[35vw] mt-3 rounded'>
                    <img src={advantage1} alt="" />
                  </div>
                  <div className='pt-[2vw] pl-[4vw]'>
                    <div className='text-[3vw] font-bold'>特有的波形波束设计及创新</div>
                    <div className='text-[2vw] font-extralight mt-[0.8vw]'>空间与多普勒联合变换的方法 SDJT</div>
                  </div>
                </div>
                <div className='mt-8 absolute flex items-end justify-end top-[30vw] right-0'>
                  <div className='pr-[4vw] text-right'>
                    <div className='text-[3vw] font-bold'>拥有 DSP 及核心算法自研能力</div>
                    <div className='text-[2vw] font-extralight mt-[0.8vw]'>语义级别的扩展目标追踪能力</div>
                  </div>
                  <div className='w-[35vw] rounded'>
                    <img src={advantage2} alt="" />
                  </div>
                </div>
                <div className='mt-8 absolute flex justify-start bottom-0 left-0'>
                  <div className='w-[35vw] rounded'>
                    <img src={advantage3} alt="" />
                  </div>
                  <div className='pl-[4vw]'>
                    <div className='text-[3vw] font-bold'>丰富的雷达量产经验</div>
                    <div className='text-[2vw] font-extralight mt-[0.8vw]'>针对电磁波数据特性优化的 AI</div>
                  </div>
                </div>
              </div>
              <div className='md:text-[1vw] text-[2vw] text-left w-[75vw] md:w-[72vw] mt-[5vw] md:mt-0'>
                <div className='mt-[0vw]'>SDJT = Spacing and Doppler Joint Transform;</div>
                <div className='mt-[0.5vw]'>DSP = Digital Signal Processing;</div>
                <div className='mt-[0.5vw]'>AI = Artificial Intelligence</div>
              </div>
            </div>
          </div>

          {/* Third */}
          <div className="flex md:w-[70vw] w-[80vw] mx-auto md:pt-[0vw] pt-[2vw] relative">
            <img
              src={star1}
              alt=""
              className="md:top-[40vw] top-[140vw] left-[-10vw] absolute animate-pulse-1 z-10"
            />
            <img
              src={star2}
              alt=""
              className="md:top-[50vw] top-[160vw] left-[3vw] absolute animate-pulse-2 z-10"
            />

            <div
              className="md:flex flex-col ml-[8.4vw]  pt-[5vw] hidden  z-10"
              // animateIn="animate__fadeIn"
              // animateOnce
            >
              <div className="text-[3.5vw]  font-extralight tracking-wider">
                sinPro 发展历程
              </div>

              <div className='h-[40vw] mt-16 w-[70vw] ml-[-8vw] font-extralight'>
                <Carousel ref={carouselEL} autoplay={true} dots={false}
                          autoplaySpeed={3000}
                          afterChange={ (res)=> {setIndex(res * 20 + 10);}}
                          className='h-[32vw]'>
                  <div>
                    <div className='flex items-end justify-start pl-20'>
                      <img src={his1} alt="" className='w-[30vw]' />
                      <div className='text-white text-[1vw] mb-[4vw] ml-[10vw]'>
                        <h1 className='text-3xl mb-3 text-bold'>2021</h1>
                        <div className='mb-1'>11月 赛恩领动成立 </div>
                        <div>12月 天使轮融资</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='flex items-end justify-start  pl-20'>
                      <img src={his2} alt="" className='w-[30vw]' />
                      <div className='text-white text-[1vw] mb-[4vw] ml-[10vw]'>
                        <h1 className='text-3xl mb-3 text-bold'>2022</h1>
                        <div className='mb-1'>3月 完成原型样机研发 </div>
                        <div>6月 完成方案验证与性能指标</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='flex items-end justify-start  pl-20'>
                      <img src={his3} alt="" className='w-[30vw]' />
                      <div className='text-white text-[1vw] mb-[4vw] ml-[10vw]'>
                        <h1 className='text-3xl mb-3 text-bold'>2022</h1>
                        <div className='mb-1'>9月 获得科小认证 </div>
                        <div>12月 与同济大学开展技术合作</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='flex items-end justify-start  pl-20'>
                      <img src={his4} alt="" className='w-[30vw]' />
                      <div className='text-white text-[1vw]  mb-[4vw] ml-[10vw]'>
                        <h1 className='text-3xl mb-3 text-bold'>2023</h1>
                        <div className='mb-1'>1月 4D成像雷达发布</div>
                        <div>3月 斩获首个全平台量产项目定点</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='flex items-end justify-start pl-20'>
                      <img src={his5} alt="" className='w-[30vw]' />
                      <div className='text-white text-[1vw]  mb-[4vw] ml-[10vw]'>
                        <h1 className='text-3xl mb-3 text-bold'>2023</h1>
                        <div className='mb-1'>4月 A 轮融资</div>
                        <div>8月 入选上海市创新型中小企业名单</div>
                      </div>
                    </div>
                  </div>
                </Carousel>

                <div className='relative'>
                  <Slider marks={marks} step={null} defaultValue={0}
                          value={index}
                          onChange={(res) => { setIndex(res);  carouselEL.current.goTo(parseInt((res - 10)/20)); }}
                          railStyle={{ backgroundColor: '#FFF' }}
                          trackStyle={{ backgroundColor: 'rgba(37, 99, 235, .9)' }}/>
                  <button
                    className={`absolute transition-all top-0 z-100 translate-x-[-50%] translate-y-[-48%] w-[10vw] flex justify-center items-center`}
                    style={{left: index + '%'}}
                  >
                    <img src={CarPic} alt="" className=''/>
                  </button>
                </div>

              </div>

            </div>

            {/* mobile */}
            <div
              className="flex-col ml-[9.4vw] pt-[5vw] md:hidden flex z-10"
              // animateIn="animate__fadeIn"
              // animateOnce
            >
              <div className="text-[7vw]  font-extralight ">
                sinPro
              </div>
              <div className="text-[7vw]  font-extralight">
                发展历程
              </div>

              <div className='w-[72vw] relative flex justify-between'>
                <div className='w-[75vw] mt-16 ml-[-8vw] font-extralight'>
                  <Carousel ref={carouselEL2} autoplay={true} dots={false}
                            autoplaySpeed={3000}
                            afterChange={ (res)=> {setIndex2(res * 20 +10);}}
                            className=''>
                    <div>
                      <div className='flex items-center justify-start flex-col'>
                        <img src={his1} alt="" className='w-[70vw]' />
                        <div className='text-white text-base'>
                          <h1 className='text-3xl mb-3 text-bold'>2021</h1>
                          <div className='mb-1'>11月 赛恩领动成立 </div>
                          <div>12月 天使轮融资</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='flex items-center justify-start flex-col'>
                        <img src={his2} alt="" className='w-[70vw]' />
                        <div className='text-white text-base'>
                          <h1 className='text-3xl mb-3 text-bold'>2022</h1>
                          <div className='mb-1'>3月 完成原型样机研发 </div>
                          <div>6月 完成方案验证与性能指标</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='flex items-center justify-start flex-col'>
                        <img src={his3} alt="" className='w-[70vw]' />
                        <div className='text-white text-base'>
                          <h1 className='text-3xl mb-3 text-bold'>2022</h1>
                          <div className='mb-1'>9月 获得科小认证</div>
                          <div>12月 与同济大学开展技术合作</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='flex items-center justify-start flex-col'>
                        <img src={his4} alt="" className='w-[70vw]' />
                        <div className='text-white text-base'>
                          <h1 className='text-3xl mb-3 text-bold'>2023</h1>
                          <div className='mb-1'>1月 4D成像雷达发布</div>
                          <div>3月 斩获首个全平台量产项目定点</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='flex items-center justify-start flex-col'>
                        <img src={his5} alt="" className='w-[70vw]' />
                        <div className='text-white text-base'>
                          <h1 className='text-3xl mb-3 text-bold'>2023</h1>
                          <div className='mb-1'>4月 A 轮融资</div>
                          <div>8月 入选上海市创新型中小企业名单</div>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>

                <div className='absolute top-[10vw] right-[0vw] h-[104vw]'>
                  <Slider marks={marks} step={null} defaultValue={0}
                          reverse
                          vertical
                          value={index2}
                          onChange={(res) => { setIndex2(res);  carouselEL2.current.goTo(parseInt((res - 10)/20)); }}
                          railStyle={{ backgroundColor: '#FFF' }}
                          trackStyle={{ backgroundColor: 'rgba(37, 99, 235, .9)' }}/>
                  <button
                    className={`absolute transition-all top-0 z-100 translate-x-[-32.5%] translate-y-[-50%] rotate-[90deg] w-[30vw]`}
                    style={{top: index2 + '%'}}
                  >
                    <img src={CarPic} alt="" className=''/>
                  </button>
                </div>
              </div>
            </div>


            {/* pc */}
            <AnimationOnScroll
              animateIn="animate__drawPath4"
              duration={0.1}
              className="w-[42vw] left-[38vw] mt-[2vw] absolute hidden md:block"
              animateOnce
            >
              <Line4 />
            </AnimationOnScroll>

            {/* mobile */}
            <div
              // animateIn="animate__drawPath4_2"
              // duration={0.1}
              className="w-[56vw] left-[34vw] mt-[8vw] absolute md:hidden "
              // animateOnce
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166 565.24" className='ml-[16vw] height-[200vw]'>
                <path
                  fill="none"
                  stroke="white"
                  strokeMiterlimit={10}
                  strokeWidth="1.5px"
                  d="M17.67,25.44H79.36s33.83-.2,33.83,40.46V550.47"/>
              </svg>
            </div>
          </div>

          {/* Fourth */}
          <img
            src={lights}
            alt=""
            className="top-[510vw] left-[0vw] width-[100vw] animate-pulse-3 absolute z-10 md:hidden"
          />
          <div className="w-[70vw] mx-auto pt-[4vw] relative">
            <img
              src={lights}
              alt=""
              className="top-[43vw] left-[-2.7vw] width-[250vw] animate-pulse-3 absolute z-10 hidden md:block"
            />

            <div
              className="flex-col mr-[5vw] mt-[2vw] text-right hidden md:flex"
              // animateIn="animate__fadeIn"
              // animateOnce
            >
              <div className="text-[2.5vw] font-extrabold pr-[8vw]">sinPro的愿景</div>
              <div className="text-[3.5vw] font-extralight pr-[8vw]">
                引领智能感知新未来
              </div>
              <div className="text-[1.4vw] font-thin tracking-wide pr-[8vw]">
                Make the Future of Intelligent Sensing
              </div>
            </div>

            <div
              className="flex-col mr-[1vw] mt-[20vw] text-right flex md:hidden"
              // animateIn="animate__fadeIn"
              // animateOnce
            >
              <div className="text-[5vw] ml- font-extrabold">sinPro愿景</div>
              <div className="text-[7vw] font-extralight">
                引领智能
              </div>
              <div className="text-[7vw] font-extralight">
                感知新未来
              </div>
              <div className="text-[3.5vw] font-extralight tracking-wider">
                Make the Future
              </div>
              <div className="text-[3.5vw] font-extralight tracking-wider">
                of Intelligent Sensing
              </div>
            </div>

            <div
              // animateIn="animate__drawPath5"
              // duration={0.1}
              className="md:w-[40vw] w-[60vw] absolute pr-[10vw] ml-[-13vw] mt-[-8vw]"
              // animateOnce
            >
              {/* <Line5 - pc/> */}
              <svg
                viewBox="0 0 529.94 953.87"
                height="60vw"
                className='md:block hidden'
              >
                <path
                  fill="none"
                  stroke="white"
                  strokeMiterlimit={10}
                  strokeWidth="3.5px"
                  d="M452.51,84H264.88s-56.81-.66-94.4,34.65S125.16,189,120.53,208.51s0,528.48,0,528.48S105.89,875.67,244.64,897.83"
                />
              </svg>

              {/* <Line5 - mobile/> */}
              <svg
                viewBox="0 0 122 404.09"
                height="120vw"
                className='md:hidden mt-[-4.2vw] ml-[-4.7vw]'
              >
                <path
                  fill="none"
                  stroke="white"
                  strokeMiterlimit={10}
                  strokeWidth="1.4px"
                  d="M99.43,23.42H74s-31,.17-31,32.67V350.76S40.4,383.33,70.7,383.33"
                />
              </svg>
            </div>
          </div>

          {/* Fifth */}

          <div className="flex w-[70vw] mx-auto pt-[42vw] pb-[30vw] relative">
            <div
              className="flex flex-col ml-[5vw] mt-[10vw]"
              // animateIn="animate__fadeIn"
              // animateOnce
            >
              <div className="md:text-[2.5vw] text-[5vw] md:ml-[-1vw] ml-[-0.vw] md:mt-[-5vw] mt-[45.5vw] font-extralight">
                sinPro价值观
              </div>
              {/* pc */}
              <div className="md:flex flex-col w-[60vw] hidden">
                <div className="flex justify-between my-[6vw]">
                  <div className="flex items-center">
                    <img src={pic1} alt="" className="w-[7vw]" />
                    <div className="flex flex-col ml-[2vw] ">
                      <div className="spacing-1 text-[2.2vw] font-extralight">
                        协作
                      </div>
                      <div className="spacing-1 text-[1.4vw] opacity-50 font-extralight">
                        Cooperation
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <img src={pic2} alt="" className="w-[7vw]" />
                    <div className="flex flex-col ml-[2vw] ">
                      <div className="spacing-1 text-[2.2vw] font-extralight">
                        信任
                      </div>
                      <div className="spacing-1 text-[1.4vw] opacity-50 font-extralight">
                        Trust
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center ml-[1vw]">
                    <img src={pic3} alt="" className="w-[5.6vw]" />
                    <div className="flex flex-col ml-[2vw] ">
                      <div className="spacing-1 text-[2.2vw] font-extralight">
                        创新
                      </div>
                      <div className="spacing-1 text-[1.4vw] opacity-50 font-extralight">
                        Creativity
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-around">
                  <div className="flex items-center ml-[5vw]">
                    <img src={pic4} alt="" className="w-[6vw]" />
                    <div className="flex flex-col ml-[2vw] ">
                      <div className="spacing-1 text-[2.2vw] font-extralight">
                        责任
                      </div>
                      <div className="spacing-1 text-[1.4vw] opacity-50 font-extralight">
                        Responsibility
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mr-[2vw]">
                    <img src={pic5} alt="" className="w-[6.5vw]" />
                    <div className="flex flex-col ml-[2vw] ">
                      <div className="spacing-1 text-[2.2vw] font-extralight">
                        效率
                      </div>
                      <div className="spacing-1 text-[1.4vw] opacity-50 font-extralight">
                        Efficiency
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* mobile */}
              <div className="flex flex-col md:w-[60vw] w-[70vw] md:hidden">
                <div className="flex items-center py-[5vw] ml-[22vw] mt-[17vw]">

                  <div className="flex flex-col mr-[2.5vw] ">
                    <div className="spacing-1 text-[6vw] font-extralight self-end">
                      协作
                    </div>
                    <div className="spacing-1 text-[4vw] opacity-50 font-extralight">
                      Cooperation
                    </div>
                  </div>
                  <img src={pic1} alt="" className="w-[20vw] mr-[10vw]" />
                </div>
                <div className="flex items-center py-[5vw] ml-[-10vw] mt-[8vw]">
                  <img src={pic2} alt="" className="w-[20vw]" />
                  <div className="flex flex-col ml-[2.5vw]">
                    <div className="spacing-1 text-[6vw] font-extralight">
                      信任
                    </div>
                    <div className="spacing-1 text-[4vw] opacity-50 font-extralight">
                      Trust
                    </div>
                  </div>
                </div>
                <div className="flex items-center py-[5vw] ml-[27vw] mt-[8vw]">
                  <div className="flex flex-col mr-[2.5vw] ">
                    <div className="spacing-1 text-[6vw] font-extralight self-end">
                      创新
                    </div>
                    <div className="spacing-1 text-[4vw] opacity-50 font-extralight">
                      Creativity
                    </div>
                  </div>
                  <img src={pic3} alt="" className="w-[17vw]" />
                </div>
                <div className="flex items-center py-[5vw] ml-[-10vw] mt-[8vw]">
                  <img src={pic4} alt="" className="w-[16vw]" />
                  <div className="flex flex-col ml-[2.5vw] ">
                    <div className="spacing-1 text-[6vw] font-extralight">
                      责任
                    </div>
                    <div className="spacing-1 text-[4vw] opacity-50 font-extralight">
                      Responsibility
                    </div>
                  </div>
                </div>
                <div className="flex items-center mr-[2vw] py-[5vw] ml-[27vw] mt-[8vw]">
                  <div className="flex flex-col mr-[2.5vw] ">
                    <div className="spacing-1 text-[6vw] font-extralight self-end">
                      效率
                    </div>
                    <div className="spacing-1 text-[4vw] opacity-50 font-extralight">
                      Efficiency
                    </div>
                  </div>
                  <img src={pic5} alt="" className="w-[16vw]" />
                </div>

              </div>
            </div>

            <div
              // animateIn="animate__drawPath6"
              // duration={0.1}
              className="w-[65vw] absolute left-[18.5vw] top-[41vw] pt-[2vw] md:h-[50vw] overflow-hidden"
              // animateOnce
            >
              {/* pc */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1163.77 1358.04"
                width="63vw"
                className='md:block hidden'
              >
                <path
                  fill="none"
                  stroke="white"
                  strokeMiterlimit={10}
                  strokeWidth="3.5px"
                  d="M38.44,105H936s65.63-3.89,111.81,46.23,46.53,98.77,46.53,112.11V1333.29"
                />
              </svg>
              {/* mobile  */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202 909.06"
                   className='mt-[52vw] ml-[12vw] w-[50vw] md:hidden'>
                <path fill="none"
                      stroke="white"
                      strokeMiterlimit={10}
                      strokeWidth="2px"
                      d="M24.1,27.29H130.67S183.35,23,183.35,90.5V803"/>
              </svg>
            </div>
          </div>

          <div className='absolute bottom-[46vh] right-[2vw] z-50 flex flex-col items-center jucfy-center md:hidden'
               onClick={onScrollToTop}>
            <img src={backToTop} alt="" className="w-[25vw] md:w-[2vw]" />
            {/* <VerticalAlignTopOutlined className="w-[10vw] md:w-[4vw] md:text-[1.8vw] text-[8vw]" /> */}
            {/*<div className='text-[1vw] mt-[0.5vw] md:text-[0.5vw] md:mt-[0.1vw]'>返回顶部</div>*/}
          </div>
        </div>
      </div>
      <Foot></Foot>
    </>
  );
}
