/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import close from './img/close.png';
import building from './img/building.png';
import success from './img/success.png';
import necessary from './img/necessary.png';
import copy from './img/copy.png';

export default function (props) {
  const { show = false, handleShow, type = 'None' } = props;
  let icon = close;
  let Word = '';

  if (type === 'building') {
    icon = building;
    Word = '网站内容搭建中 ， 敬请期待';
  } else if (type === 'success') {
    icon = success;
    Word = '信息提交成功 ， 谢谢';
  } else if (type === 'necessary') {
    icon = necessary;
    Word = '处为必填项，请务必填写！';
  } else if (type === 'email') {
    icon = necessary;
    Word = '邮箱格式不正确，请重新输入!'
  } else {
    icon = copy;
    Word = '已复制招聘邮箱地址 ：hr@sinpro.ai 可直接发送简历';
  }

  useEffect(() => {
    if (show === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [show]);

  return (
    <div className={`${show ? 'absolute touch-none' : 'hidden'} top-0 left-0 right-0 w-[100vw] h-[1000vh] bg-gray-900/70 z-50`}>
      <div className="flex flex-col fixed top-[38vh]
        md:left-[28vw] left-[15vw] md:w-[34vw] w-[70vw] md:h-[15vw] h-[35vw] bg-white rounded-lg"
      >
        <div className="relative md:w-[34vw] w-[70vw] bg-blue-400 md:h-[6vw] h-[10vw] rounded-t-lg">
          <img onClick={handleShow} alt="" className="absolute md:right-[2vw] right-[4vw] md:top-[1.4vw] top-[2.6vw] md:w-[1.4vw] w-[4vw]" src={close} />
        </div>
        <div className="flex justify-between items-center h-[15vw]">
          <img src={icon} alt="" className="md:ml-[6vw] ml-[3vw] md:w-[5vw] w-[20vw] md:mt-[0vw] mt-[8vw]" />
          <span className="md:mr-[6vw] mr-[4vw] md:text-[1.25vw] text-[3vw] md:w-[20vw] w-[49vw] md:mt-[0vw] mt-[7vw] text-black text-center">
            <span className={`${type === 'necessary' ? ' text-red-600' : 'hidden'}`}>*</span>
            {' '}
            {Word}
          </span>
        </div>
      </div>

    </div>
  );
}
