import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';

const root = document.getElementById('root');
// eslint-disable-next-line react/jsx-filename-extension
render(<App />, root);

// render(<Test />,root)
