import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { baseServerUrl } from "../../utils/server.js";
const baseUrl = `${baseServerUrl}/api/article`;

export default function LasterNews() {
  const [news, setNews] = useState([]);
  const getOtherNews = async () => {
    const res = await axios.get(`${baseUrl}/search`, {
      params: {
        cur: 1,
        pageSize: 5,
      },
    });
    const result = res.data;
    if (result.code !== 200) {
      return;
    }
    setNews(result.data.list);
    console.log(result.data);
  };
  useEffect(() => {
    getOtherNews();
  }, []);

  return (
    <div className="font-sans mx-auto mt-10 mb-16 p-10 shadow shadow-zinc-400 rounded-lg">
      <div className="mb-4 text-sinpro-blue2">
        <div className="text-xl font-bold">最新动态</div>
        <div className="flex items-center text-xl font-bold mt-[0.2vw]">
          <div>NEWS</div>
          <div className="flex ml-6">
            <span className="w-[1.6vw] h-[1.6vw] md:w-[0.6vw] md:h-[0.6vw] bg-sinpro-blue2 mr-2"></span>
            <span className="w-[1.6vw] h-[1.6vw] md:w-[0.6vw] md:h-[0.6vw] bg-sinpro-blue2 mr-2 opacity-80"></span>
            <span className="w-[1.6vw] h-[1.6vw] md:w-[0.6vw] md:h-[0.6vw] bg-sinpro-blue2 mr-2 opacity-60"></span>
            <span className="w-[1.6vw] h-[1.6vw] md:w-[0.6vw] md:h-[0.6vw] bg-sinpro-blue2 mr-2 opacity-40"></span>
            <span className="w-[1.6vw] h-[1.6vw] md:w-[0.6vw] md:h-[0.6vw] bg-sinpro-blue2 opacity-20"></span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1">
        {news.map((item) => {
          return (
            <Link
              to={`/News/detail/${item.cid}/${item.id}`}
              key={item.id}
              className="border-t first:border-0"
            >
              <div key={item.id} className={`cursor-pointer text-left py-3`}>
                <div className="text-sm text-sinpro-blue2">{item.title}</div>
                <div className="text-xs text-gray-600">
                  {item.updatedAt.substring(0, 10)}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
