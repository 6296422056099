import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Foot from '../News/Foot';
import LasterNews from './laster-news';
import OtherNews from './other-news';


import { baseServerUrl } from '../../utils/server.js';
const baseUrl = `${baseServerUrl}/api/article`;
const listWords = {
  1: '新闻资讯',
  2: '主题分享',
}
export default function NewsDetail() {
  const navigate = useNavigate()
  const rotparams = useParams();

  const [detail, setDetail] = useState({});
  const getContent = async () => {
    const res = await axios.get(`${baseUrl}/detail`, {
      params: {
        id: rotparams.id
      }
    });
    const result = res.data;
    if (result.code !== 200) {
      return
    }
    const _detail = result.data;
    _detail.contentStr = _detail.content.replace(/src="([^"]+)"/g, function(match, src) {
      var newSrc = (!src.startsWith("http") ? baseServerUrl : '') + src;
      return 'src="' + newSrc + '"';
    });

    setDetail(_detail);
    console.log(_detail);
  }

  useEffect(() => {
    getContent()
    window.scrollTo(0, 0);
  }, [rotparams.id]);

  const getCidType = (cid) => {
    return listWords[cid]
  }

  return (
    <>
      <div className="font-sans bg-cover text-black w-[100vw] mt-[-12vw] hidden md:block">
        <div className='grid grid-cols-4 w-[70vw] mx-auto pt-[20vw] pb-[5vw]'>
          <div className='col-span-3 mr-8'>
            <div className="flex flex-col items-start mx-auto text-[2vw]">
              <div className='text-base text-gray-400'>首页 / <Link to={`/News?cid=${rotparams.cid}`}>{getCidType(rotparams.cid)}</Link></div>
            </div>
            {
              detail.id ?
                <div>
                  <div className="flex flex-col items-start pt-[2vw] mx-auto text-[2vw]">
                    <div className='text-2xl text-sinpro-blue2 font-bold'>{detail.title}</div>
                  </div>
                  <div className="flex flex-col items-start pt-[2vw] pb-[1vw] mx-auto text-[2vw]">
                    <div className='text-sm text-gray-400'>发布时间：{ detail.createdAt.substring(0, 10)}</div>
                  </div>
                  <div className='mt-4'>
                    <div className='w-16 h-[0.18vw] bg-sinpro-blue2 rounded mb-4'></div>
                    <div dangerouslySetInnerHTML={{__html:detail.contentStr}}></div>
                  </div>
                </div>
              : <div></div>
            }

            <div className='mt-[1vw] mb-[-3vw]'><button className='text-sinpro-blue2' onClick={() => navigate(-1)}>返回上页</button></div>
          </div>

          <div className='col-span-1 rounded relative'>
            <LasterNews />
          </div>
        </div>
        <OtherNews />
        <Foot />
      </div>
      {/* mobile */}
      <div className='font-sans bg-cover text-black w-[90vw] mx-auto mt-[12vw] block md:hidden'>
        <div className='col-span-2 pt-5'>
          <div className="flex flex-col items-start mx-auto text-[2vw]">
            <div className='text-base text-gray-400'>首页 / <Link to={`/News?cid=${rotparams.cid}`}>{getCidType(rotparams.cid)}</Link></div>
          </div>
          {
            detail.id ?
              <div>
                <div className="flex flex-col items-start pt-[2vw] mx-auto text-[2vw]">
                  <div className='text-2xl text-sinpro-blue2 font-bold'>{detail.title}</div>
                </div>
                <div className="flex flex-col items-start pt-[2vw] pb-[1vw] mx-auto text-[2vw]">
                  <div className='text-sm text-gray-400'>发布时间：{ detail.createdAt.substring(0, 10)}</div>
                </div>
                <div className='mt-4'>
                  <div className='w-16 h-[0.8vw] bg-sinpro-blue2 rounded mb-4'></div>
                  <div dangerouslySetInnerHTML={{__html:detail.contentStr}}></div>
                </div>
              </div>
            : <div></div>
          }
        </div>
        <div className='col-span-1 rounded relative'>
          <LasterNews />
        </div>

        <OtherNews />
        <Foot />
      </div>
    </>
  );
}
