import {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, useParams} from 'react-router-dom';
import {baseServerUrl} from '../../utils/server.js';

const baseUrl = `${baseServerUrl}/api/article`;

export default function OtherNews() {
  const [news, setNews] = useState([]);
  const rotparams = useParams();
  const getOtherNews = async () => {
    const res = await axios.get(`${baseUrl}/search`, {
      params: {
        cur: 1,
        pageSize: 3,
        cid: rotparams.cid
      }
    });
    const result = res.data;
    if (result.code !== 200) {
      return
    }
    setNews(result.data.list);
    console.log(result.data);
  }
  useEffect(() => {
    getOtherNews();
  }, [rotparams.cid])

  return (
    <>
      <div className='font-sans w-[70vw] mx-auto mb-16 hidden md:block'>
        <div className='border-l-4 border-blue-400 pl-2 mb-4 flex items-center'>
          <span>其他相关新闻</span>
          <div className='flex-1 h-[1px] bg-zinc-400 ml-4'></div>
        </div>
        <div className='grid grid-cols-3 gap-4'>
          {news.slice(0, 3).map((item) => {
            return <Link to={`/News/detail/${item.cid}/${item.id}`}>
              <div key={item.id} className='cursor-pointer text-left'>
                <div><img src={baseServerUrl + item.img} className='rounded aspect-[2/1] object-contain' alt='' /></div>
                <div className='mt-1 mb-[0.2vw] text-base'>{item.title}{ item.id}</div>
                <div className='text-xs text-gray-400'>{ item.updatedAt.substring(0, 10)}</div>
              </div>
            </Link>
          })}
        </div>
      </div>
      {/* mobile */}
      <div className='font-sans w-[90vw] mx-auto mb-16 md:hidden'>
        <div className='border-l-4 border-blue-400 pl-2 mb-4 flex items-center'>
          <span>其他相关新闻</span>
          <div className='flex-1 h-[1px] bg-zinc-400 ml-4'></div>
        </div>
        <div className='grid grid-cols-1 gap-4'>
          {news.slice(0, 3).map((item) => {
            return <Link to={`/News/detail/${item.cid}/${item.id}`}>
              <div key={item.id} className='cursor-pointer text-left'>
                <div><img src={baseServerUrl + item.img} className='rounded aspect-[2/1] object-contain' alt='' /></div>
                <div className='mt-1 mb-[0.2vw] text-base'>{item.title}{ item.id}</div>
                <div className='text-xs text-gray-400'>{ item.updatedAt.substring(0, 10)}</div>
              </div>
            </Link>
          })}
        </div>
      </div>
    </>
  )
}
